module.exports = {
  'title_login': 'Sign In',
  'subtitle': 'Enter your registered AYANA Rewards email address to proceed',
  'placeholder_email': 'Email address',
  'placeholder_password': 'Password',
  'remember_label': 'Remember me',
  'forgot_password_label': 'Forgot password?',
  'btn_label': 'Sign In',
  'title_another_login': 'Or sign in using',
  'login_error_email_text': 'Could not find your email address',
  'login_error_email_text_empty': 'Email can not be null',
  'login_error_password_text': 'Wrong password',
  'login_error_password_text_empty': 'Password can not be null',
  'sugest_register_link': 'Would you like to ',
  'register_link': 'create a new account',
  'sugest_register_link_2': ' instead?',
  'btn_next_label': 'Next',
  'title_register': 'Not an AYANA Rewards member yet? ',
  'join_label': 'Join Now',
  'password_info': 'Please enter your password to proceed',
  'login_error_no_connection': 'Unable to connect. Please check your internet connection and try again.'
}