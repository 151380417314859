import React, { Component } from "react";
import { Link, navigate } from "gatsby";

import Layout from "../layouts/en";
import SEO from "../components/seo";
import Login from "../services/Login";
import LoginLang from "../data/static-pages/pages/login/en";
import { isLoggedIn, getCurrentUser } from "../services/Auth";

export default class LoginPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.urlSignUp = "/signup";
    this.urlForgetPassword = "/forget-password";
    this.urlRedirect = "/member";
    this.activationUrl = "/activation";
    this.verifyAccountUrl = "/verify-account";
    this.loginUrl = "/login";
    this.redemptionUrl = "/member/redemption";
    this.langKey = "en";
  }

  componentDidMount() {
    this.checkLoginStatus();
  }

  checkLoginStatus() {
    if (isLoggedIn() == true) navigate("/member");
  }

  render() {
    return (
      <Layout location={this.props.location} footer_margin={true}>
        <SEO title="Login " keyword="Login" decription="Login" />

        <Login
          props={this.props}
          urlRedirect={this.urlRedirect}
          langData={LoginLang}
          urlSignUp={this.urlSignUp}
          urlForgetPassword={this.urlForgetPassword}
          activationUrl={this.activationUrl}
          verifyAccountUrl={this.verifyAccountUrl}
          loginUrl={this.loginUrl}
          redemptionUrl={this.redemptionUrl}
          langKey={this.langKey}
        />
      </Layout>
    );
  }
}
